<template>
	<div class="container">
		<Header  :flag="flag"/>	
		<div class="banner">
			<div class="banner-content">
				 <span class="banner-sub-title">AI智能名片-引领智能销售新时代</span>
				 <br/>
				 <span class="banner-sub-title1">AI+微信+小程序，连接微信10亿用户</span>
				 <br/>
				 <div class="banner-qrcode">
					 <img src="../assets/ai_qrcode.jpg" width="150px" height="150px"/>
				 </div>
			 </div>
		</div>
		<div class="main">
			<div class="mingp">
				<div class="mp-content">
					<div class="mp-content-l">
						<div class="content-left">
							<span class="we-title">我们的智能名片</span>
							<br/>
							<div class="kj-title">
								<span class="kj-title-text">科技范的智能名片</span>
								<br/>
								<div class="server-border"></div>
							</div>
							<span class="sub-title">
								向发送名片照片的时代说再见，用一张人工智能名片小程序展示出您对前沿科技的驾驭。
							</span>
							<div class="content-text">
								<ul>
									<li>● 个人信息个性化展示</li>
									<li>● 产品或服务展示</li>
									<li>● 随时更新名片信息，一张“活的”名片</li>
									<li>● 通过微信社交实现人脉裂变</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="mp-content-r">
						<img src="../assets/img/btimg_1_1.png" />
					</div>
				</div>
			</div>
		</div>	
		<div class="middle1">
			<div class="middle1-div">
				<div class="middle-box">
					<div class="middle-box-img">
						<img src="../assets/img/btimg_2.png" />
					</div>
				</div>
				<div class="middle-box">
					<div class="middle-box-content">
						<div class="content-div">
							<span class="work-title">智能名片能做到的</span>
							<br/>
							<div class="box-title">
								<span class="box-title-text">
									制作简单   立体多维
								</span>
								<br/>
								<div class="server-border"></div>
							</div>
							<div class="box-content">
								<ul class="box-ul">
									<li>● 模块化、数据化、一体化的个性名片，操作方便，制作简单</li>
									<li>● 展示个人信息、公司信息、产品信息，并可以根据用户实际情况调整个人、公司和产品信息</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			
		</div>	
		<div class="middle2">
			<div class="middle-content">
				<div class="middle-box">
					<div class="middle-box-content">
						<div class="content-div">
							<span class="work-title">智能名片能做到的</span>
							<br/>
							<div class="box-title1">
								<span class="box-title1-text">
									推广引流   提升品牌影响力
								</span>
								<br/>
								<div class="server-border"></div>
							</div>
							<div class="box-content">
								<ul class="box-ul">
									<li>● 基于微信的10亿用户流量，借助一定的推广策略实现快速传播，高效获取目标客户</li>
									<li>● 以名片统一的风格、统一的企业介绍和产品介绍，更好的推广企业品牌文化、提升品牌价值</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div class="middle-box">
					<div class="middle-box-img">
						<img src="../assets/img/btimg_3.png" />
					</div>
				</div>
			</div>
		</div>
		<div class="middle3">
			<div class="middle3-div">
				<div class="middle-box">
					<div class="middle-box-img">
						<img src="../assets/img/btimg_4.png" />
					</div>
				</div>
				<div class="middle-box">
					<div class="middle-box-content">
						<div class="content-div">
							<span class="we-title">智能名片能做到的</span>
							<br/>
							<div class="box-title">
								<span class="box-title-text">开源节流  降低成本</span>
								<br/>
								<div class="server-border"></div>
							</div>
							<div class="box-content">
								<ul class="box-ul">
									<li>● 智能名片的推广与发展大大解决了名片制作的成本，省时省力省钱</li>
									<li>● 智能名片管理由专业人员服务，根据用户的具体需求，可对名片数据信息进行实时更新并保存使用</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="scenes">
			<div class="scenes-title">
				<span class="scenes-title-text">智能名片使用场景</span>
				<br/>
				<div class="server-border">
				</div>
			</div>
			<div class="scenes-content">
				<div class="box-img">
					<img src="../assets/img/ai-bg1.png"/>
				</div>
				<div class="box-img">
					<img src="../assets/img/ai-bg2.png"/>
				</div>
				<div class="box-img">
					<img src="../assets/img/ai-bg3.png"/>
				</div>
			</div>
		</div>	
		<Footer />
	</div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'	
export default {
	metaInfo: {
		title: '智能名片',
		meta: [
		  {
			name: 'keywords',
			content: '智能名片,个性化个人信息展示,'
		  },
		  {
			name: 'description',
			content: '蓝莓科技智能名片制作简单、立体多维、个性化展示，帮助您推广引流，提高影响力，开源节流，节约成本。'
		  }
		]
	},
	name: 'Intelligent',
	components: {
		Header,
		Footer
	},	
	data(){
		return{
			flag:'solution'
		}	
	}	
}

</script>

<style scoped lang="less">
	.banner{
		 padding-top: 80px;
		 height: 450px;
		 background-image: url(https://file.lanmeihulian.com/banner_1.png);
		 background-repeat: no-repeat;
		 background-size:100% 100%;
		 .banner-content{
			 width: 1200px;
			 margin: auto;
			 text-align: center;
			 .banner-sub-title{
				 display: inline-block;
				 height: 100px;
				 margin-top: 100px;
				 font-size: 60px;
				 font-weight: 600;
				 color: #fff;
			 }
			 .banner-sub-title1{
				 font-size: 28px;
				 color: #fff;
			 }
			 .banner-qrcode{
				 margin: 20px auto;
				 text-align: center;
				 height: 200px;
				 width: 400px;
			 }
		 }
	}	
	.main{
		width: 1200px;
		margin: auto;
		.mingp{
			margin-top: 60px;
			height: 580px;
			.mp-title{
				font-size: 38px;
				font-weight: 600;
				line-height: 80px;
				text-align: center;
			}
			.mp-content{
				height: 580px;
				display: flex;
				display: -webkit-flex;
				flex-direction: row;
				justify-content: center;
				.mp-content-l{
					width: 500px;
					.content-left{
						.we-title{
							font-size: 20px;
							color: #131313;
							line-height: 30px;
							text-align: center;
						}
						.kj-title{
							height: 100px;
							width: 260px;
							.kj-title-text{
								display: inline-block;
								padding-top: 8px;
								padding-bottom: 14px;
								height: 50px;
								width: 260px;
								text-align: left;
								margin: auto;
								font-size: 32px;
								color: #131313;
								font-weight: 600;
							}
							.server-border{
								width: 100px;
								background-color: #409EFF;
								height: 8px;
								border-radius: 5px;
							}
						}
						.sub-title{
							display: inline-block;
							width: 300px;
							color: #666666;
							margin-top: 16px;
							font-size: 18px;
							line-height: 35px;
							text-align: left;
						}
						.content-text{
							padding-top: 20px;
							ul li{
								font-size: 20px;
								color: #333333;
								height: 50px;
								padding-left: 30px;
							}
						}
					}
				}
				.mp-content-r{
					// margin-top: 60px;
					img{
						width: 400px;
						height: 600px;
					}
				}
			}	
		}
	}
	.middle1{
		height: 500px;
		padding-top: 100px;
		background-color: rgb(244,246,255);
		.middle1-div{
			width: 1200px;
			margin: auto;
			display: flex;
			display: -webkit-flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;
			.middle-box{
				width: 600px;
				height: 500px;
				.middle-box-img{
					text-align: center;
				}
				.middle-box-content{
					.content-div{
						margin: auto;
						width: 400px;
						.work-title{
							font-size: 20px;
							color: #131313;
							line-height: 30px;
							text-align: center;
						}	
						.box-title{
							.box-title-text{
								display: inline-block;
								padding-top: 8px;
								padding-bottom: 14px;
								height: 50px;
								width: 300px;
								text-align: left;
								margin: auto;
								font-size: 32px;
								color: #131313;
								font-weight: 600;
							}
							.server-border{
								width: 100px;
								background-color: #409EFF;
								height: 8px;
								border-radius: 5px;
							}
						}
						.box-content{
							margin-top: 40px;
							width: 400px;
							.box-ul{
								li{
									margin-top: 18px;
									font-size: 18px;
									line-height: 35px;
									text-align: left;
									height: 70px;
									line-height: 30px;
								}
							}
						}
					}
				}
			}	
		}
	}
	.middle2{
		width: 1200px;
		margin: auto;
		.middle-content{
			height: 560px;
			margin-top: 120px;
			display: flex;
			display: -webkit-flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;
			.middle-box{
				height: 500px;
				width: 580px;
				.middle-box-img{
					text-align: right;
				}
				.middle-box-content{
					margin-top: 60px;
					.content-div{
						margin: auto;
						width: 400px;
						.work-title{
							font-size: 20px;
							color: #131313;
							line-height: 30px;
							text-align: center;
						}	
						.box-title1{
							.box-title1-text{
								display: inline-block;
								padding-top: 8px;
								padding-bottom: 14px;
								height: 50px;
								width: 380px;
								text-align: left;
								margin: auto;
								font-size: 32px;
								color: #131313;
								font-weight: 600;
							}
							.server-border{
								width: 100px;
								background-color: #409EFF;
								height: 8px;
								border-radius: 5px;
							}
						}
						.box-content{
							margin-top: 40px;
							width: 400px;
							.box-ul{
								li{
									margin-top: 18px;
									font-size: 18px;
									line-height: 35px;
									text-align: left;
									height: 70px;
									line-height: 30px;
								}
							}
						}
					}
				}
			}
		}
	}
	.middle3{
		padding-top: 100px;
		background-color: rgb(244,246,255);
		.middle3-div{
			margin: auto;
			height: 450px;
			width: 1200px;
			margin-top: 30px;
			display: flex;
			display: -webkit-flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;
			.middle-box{
				height: 450px;
				width: 600px;
				.middle-box-img{
					text-align: center;
				}
				.middle-box-content{
					.content-div{
						margin: auto;
						width: 400px;
						.we-title{
							font-size: 20px;
							color: #131313;
							line-height: 30px;
							text-align: center;
						}	
						.box-title{
							.box-title-text{
								display: inline-block;
								padding-top: 8px;
								padding-bottom: 14px;
								height: 50px;
								width: 300px;
								text-align: left;
								margin: auto;
								font-size: 32px;
								color: #131313;
								font-weight: 600;
							}
							.server-border{
								width: 100px;
								background-color: #409EFF;
								height: 8px;
								border-radius: 5px;
							}
						}
						.box-content{
							margin-top: 40px;
							width: 400px;
							.box-ul{
								li{
									margin-top: 18px;
									font-size: 18px;
									line-height: 35px;
									text-align: left;
									height: 70px;
									line-height: 30px;
								}
							}
						}
					}
				}
			}
		}	
	}
	.scenes{
		margin-top: 83px;
		.scenes-title{
			text-align: center;
			.scenes-title-text{
				text-align: center;
				font-size: 36px;
				font-weight: 600;
				line-height: 80px;
				color: #131313;
			}
			.server-border{
				width: 100px;
				background-color: #409EFF;
				height: 8px;
				border-radius: 5px;
				margin: auto;
			}
			
		}
		.scenes-content{
			height: 700px;
			width: 1200px;
			margin: auto;
			margin-top: 60px;
			display: flex;
			display: -webkit-flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-between;
			.box-img{
				width: 300px;
				img{
					width: 300px;
					height: 600px;
				}
			}
		}
	}
</style>
