var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('Header',{attrs:{"flag":_vm.flag}}),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('div',{staticClass:"banner-content"},[_c('span',{staticClass:"banner-sub-title"},[_vm._v("AI智能名片-引领智能销售新时代")]),_c('br'),_c('span',{staticClass:"banner-sub-title1"},[_vm._v("AI+微信+小程序，连接微信10亿用户")]),_c('br'),_c('div',{staticClass:"banner-qrcode"},[_c('img',{attrs:{"src":require("../assets/ai_qrcode.jpg"),"width":"150px","height":"150px"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c('div',{staticClass:"mingp"},[_c('div',{staticClass:"mp-content"},[_c('div',{staticClass:"mp-content-l"},[_c('div',{staticClass:"content-left"},[_c('span',{staticClass:"we-title"},[_vm._v("我们的智能名片")]),_c('br'),_c('div',{staticClass:"kj-title"},[_c('span',{staticClass:"kj-title-text"},[_vm._v("科技范的智能名片")]),_c('br'),_c('div',{staticClass:"server-border"})]),_c('span',{staticClass:"sub-title"},[_vm._v(" 向发送名片照片的时代说再见，用一张人工智能名片小程序展示出您对前沿科技的驾驭。 ")]),_c('div',{staticClass:"content-text"},[_c('ul',[_c('li',[_vm._v("● 个人信息个性化展示")]),_c('li',[_vm._v("● 产品或服务展示")]),_c('li',[_vm._v("● 随时更新名片信息，一张“活的”名片")]),_c('li',[_vm._v("● 通过微信社交实现人脉裂变")])])])])]),_c('div',{staticClass:"mp-content-r"},[_c('img',{attrs:{"src":require("../assets/img/btimg_1_1.png")}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"middle1"},[_c('div',{staticClass:"middle1-div"},[_c('div',{staticClass:"middle-box"},[_c('div',{staticClass:"middle-box-img"},[_c('img',{attrs:{"src":require("../assets/img/btimg_2.png")}})])]),_c('div',{staticClass:"middle-box"},[_c('div',{staticClass:"middle-box-content"},[_c('div',{staticClass:"content-div"},[_c('span',{staticClass:"work-title"},[_vm._v("智能名片能做到的")]),_c('br'),_c('div',{staticClass:"box-title"},[_c('span',{staticClass:"box-title-text"},[_vm._v(" 制作简单 立体多维 ")]),_c('br'),_c('div',{staticClass:"server-border"})]),_c('div',{staticClass:"box-content"},[_c('ul',{staticClass:"box-ul"},[_c('li',[_vm._v("● 模块化、数据化、一体化的个性名片，操作方便，制作简单")]),_c('li',[_vm._v("● 展示个人信息、公司信息、产品信息，并可以根据用户实际情况调整个人、公司和产品信息")])])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"middle2"},[_c('div',{staticClass:"middle-content"},[_c('div',{staticClass:"middle-box"},[_c('div',{staticClass:"middle-box-content"},[_c('div',{staticClass:"content-div"},[_c('span',{staticClass:"work-title"},[_vm._v("智能名片能做到的")]),_c('br'),_c('div',{staticClass:"box-title1"},[_c('span',{staticClass:"box-title1-text"},[_vm._v(" 推广引流 提升品牌影响力 ")]),_c('br'),_c('div',{staticClass:"server-border"})]),_c('div',{staticClass:"box-content"},[_c('ul',{staticClass:"box-ul"},[_c('li',[_vm._v("● 基于微信的10亿用户流量，借助一定的推广策略实现快速传播，高效获取目标客户")]),_c('li',[_vm._v("● 以名片统一的风格、统一的企业介绍和产品介绍，更好的推广企业品牌文化、提升品牌价值")])])])])])]),_c('div',{staticClass:"middle-box"},[_c('div',{staticClass:"middle-box-img"},[_c('img',{attrs:{"src":require("../assets/img/btimg_3.png")}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"middle3"},[_c('div',{staticClass:"middle3-div"},[_c('div',{staticClass:"middle-box"},[_c('div',{staticClass:"middle-box-img"},[_c('img',{attrs:{"src":require("../assets/img/btimg_4.png")}})])]),_c('div',{staticClass:"middle-box"},[_c('div',{staticClass:"middle-box-content"},[_c('div',{staticClass:"content-div"},[_c('span',{staticClass:"we-title"},[_vm._v("智能名片能做到的")]),_c('br'),_c('div',{staticClass:"box-title"},[_c('span',{staticClass:"box-title-text"},[_vm._v("开源节流 降低成本")]),_c('br'),_c('div',{staticClass:"server-border"})]),_c('div',{staticClass:"box-content"},[_c('ul',{staticClass:"box-ul"},[_c('li',[_vm._v("● 智能名片的推广与发展大大解决了名片制作的成本，省时省力省钱")]),_c('li',[_vm._v("● 智能名片管理由专业人员服务，根据用户的具体需求，可对名片数据信息进行实时更新并保存使用")])])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scenes"},[_c('div',{staticClass:"scenes-title"},[_c('span',{staticClass:"scenes-title-text"},[_vm._v("智能名片使用场景")]),_c('br'),_c('div',{staticClass:"server-border"})]),_c('div',{staticClass:"scenes-content"},[_c('div',{staticClass:"box-img"},[_c('img',{attrs:{"src":require("../assets/img/ai-bg1.png")}})]),_c('div',{staticClass:"box-img"},[_c('img',{attrs:{"src":require("../assets/img/ai-bg2.png")}})]),_c('div',{staticClass:"box-img"},[_c('img',{attrs:{"src":require("../assets/img/ai-bg3.png")}})])])])
}]

export { render, staticRenderFns }